import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/vue';
import { useToast } from 'vue-toast-notification';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const toast = useToast();
    const toastOptions = {
      duration: 10000,
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'invalid_email') {
          toast.error("No account found with that email. Please try again or create a new account.", toastOptions);
        } else if (message === "incorrect_password") {
          toast.error("Sorry, we were not able to log you in with that email & password combination.", toastOptions);
        } else if (message.includes("This email address is already registered")) {
          toast.error("This email address is already registered. Please log in with this address or choose another one.", toastOptions);
        }

        Sentry.captureException(new Error(`GraphQL error: ${message}`), {
          extra: {
            locations,
            path,
          },
        });
      });
    }

    if (networkError) {
      toast.error(`Network error: ${networkError}`, { position: 'bottom' });

      Sentry.captureException(new Error(`Network error: ${networkError}`));
    }
  });

  // Provide the errorLink to be used in Apollo Client setup
  nuxtApp.provide('apolloErrorLink', errorLink);
});
