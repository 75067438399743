import { default as financingzHFCvUjwSfMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as index8sxubtURRiMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyddLB0lj56YMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamsyWtY6DABRMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45areaNLNpgcetimMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areaAE486vjzO2Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45areaIvio2KpaWvMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaQJBIQbzflFMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaq2cvC9r4jrMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areahi9sM7Yfj9Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45arearHL0sW99YAMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45area4bvkilRwi5Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93RQEi1rYEnRMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexXJ4HNZYVOjMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as index8rh3jJZ0MLMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93nrAhjipTTAMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexzqypxZqbk0Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadows8PhQgDw8gHMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estatesqmO0oV4AC5Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templateuca9l1tYzzMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as indexoUmc236JfIMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45questionyyZxyIDSv9Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunities1Eb1rU3JxVMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq4BBkl8C86mMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesVP52I5NXwJMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexhQj7MIpqg4Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsb0Asy2vmTnMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryPkNChmQS72Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offersG4d3SJDNotMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexcXZXUNtxMqMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexsAWCiTcjlgMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexGV5NDjKyPeMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexIEJdspPnjWMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as indexdgyPpT0Ll0Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionSrlOZVHqjgMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_93ooZZOZear8Meta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexZjpjKq2LEVMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexKI1wkwm2UqMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93IwXBjItEQmMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as index6XdzIpzS1EMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favorites6wV5TwkzPjMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45search5wy2UgzBNCMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communities19Ph1HbQCPMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as easyhouseSK00qliCScMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionGQvkZs6nQDMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageTOoUJbOeMfMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexwIUkkbBrodMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsT3YgT67PwIMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as indexB78iTnKwmUMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandboxh9Yma6PdlWMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserveTk9TvF7euBMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creek80FqcvVagOMeta } from "/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93nrAhjipTTAMeta || {},
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/coming-soon/template.vue").then(m => m.default || m)
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    meta: indexoUmc236JfIMeta || {},
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexGV5NDjKyPeMeta || {},
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-easyhouse",
    path: "/our-homes/home-collections/easyhouse",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-limitededition",
    path: "/our-homes/home-collections/limitededition",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-wee-cottage",
    path: "/our-homes/home-collections/wee-cottage",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/codebuild/output/src2095573538/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]